import { Outlet } from "react-router";
import React, { Component } from 'react';

export class Layout extends Component {
    constructor() {
        super();
        this.state = {
            gdpr: false,
            year18: false
        };
    }

    componentDidMount() {
        if (localStorage.getItem('gdpr') === 'true') {
            this.setState({
                gdpr: true
            });
        }
        else {
            this.setState({
                gdpr: false
            });
        }

        if (localStorage.getItem('year18') === 'true') {
            this.setState({
                year18: true
            });
        }
        else {
            this.setState({
                year18: false
            });
        }
    }

    accpetGDPR() {
        localStorage.setItem('gdpr', 'true');
        this.setState({
            gdpr: true
        });
    }

    disallowGDPR() {
        localStorage.setItem('gdpr', 'false');
        this.setState({
            gdpr: false
        });
    }

    accpet18() {
        localStorage.setItem('year18', 'true');
        this.setState({
            gdpr: true
        });
        window.location.reload();
    }

    disallow18() {
        localStorage.setItem('year18', 'false');
        this.setState({
            gdpr: false
        });
        window.location.href = 'https://www.google.com';
    }

    render() {
        return(
            <div className="container">
                {this.state.year18 === true &&
                <Outlet />
                }
                {!this.state.year18 &&
                <div className="year18Lock">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <p className="warningtext">Weboldalunkon csak 18 éven felüliek vásárolhatnak.<br />Kérjük erősítse meg, hogy elmúlt 18 éves!</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <button onClick={() => this.accpet18()} className="btn btn-success">Elmúltam 18 éves</button>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <button onClick={() => this.disallow18()} className="btn btn-danger">Nem múltam el 18 éves</button>
                        </div>
                    </div>
                </div>
                }
                <div className="row">
                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12 footerbg">
                        <ul className="footer-menu">
                            <li className="footer-link"><a href="/">Főoldal</a></li>
                            <li className="footer-link"><a href="/impresszum">Impresszum</a></li>
                            <li className="footer-link"><a href="/gdpr">Adatkezelés</a></li>
                            <li className="footer-link"><a href="/aszf">Általános szerződési feltételek</a></li>
                        </ul>
                    </div>
                </div>
                {!this.state.gdpr && 
                <div className="gdpr-block">
                    Weboldalunk a megfelelő működés és a jobb felhasználói élmény érdekében sütiket használ. Részletes információt a sütikről itt találsz: <a href="/gdpr">Adatvédelmi nyilatkozat - GDPR</a>
                    <button onClick={() => this.accpetGDPR()} className="btn btn-success accept-button">Elfogadom</button>
                </div>
                }
            </div>
        )
    }
}
export default Layout;