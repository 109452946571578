import React, { Component } from 'react';
import { BookItem } from './book-item';

export class BookList extends Component {

    constructor() {
        super();
        this.state = {
            books: [],
            pager: 0,
            limit: 12,
            resultCount: 0,
            keyword: ''
        };
        this.searchByKeyword = this.searchByKeyword.bind(this);
    }

    componentDidMount() {
        this.getBooksList();
    }

    getBooksList() {
        let url = "";
        if (this.state.keyword === '') {
            url = "https://konyvkupac.com/dev.php?pager="+this.state.pager+"&limit="+this.state.limit;
        }
        else {
            url = "https://konyvkupac.com/dev.php?keyw="+this.state.keyword+"&pager="+this.state.pager+"&limit="+this.state.limit;
        }
        fetch(url)
        .then(res => res.json())
        .then((result) => {
            this.setState({
                books: result.rows,
                resultCount: result.rowscount
            });
        });
    }

    goPrev() {
        if (this.state.pager>=12) {
            this.setState({
                pager: this.state.pager-12
            }, () => {
                this.getBooksList();
            });
        }
    }

    goNext() {
        if (this.state.pager<this.state.resultCount) {
            this.setState({
                pager: this.state.pager+12
            }, () => {
                this.getBooksList();
            });
        }
    }

    searchByKeyword(event) {
        this.setState({
            keyword: event.target.value
        }, () => {
            this.getBooksList();
        });
    }
    
    render() {
        return(
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 search">
                    <input type="text" onKeyUp={this.searchByKeyword} className="form-control" placeholder="Keresés cím, szerző vagy sorozat neve szerint..." />
                    <label><span className="badge bg-success">Összesen {this.state.resultCount} találat</span></label>
                </div>
                <div className="col">
                    <div className="row">
                        <button type="button" className="pagination-left" onClick={() => this.goPrev()}>‹</button>
                        {this.state.books !== null ? this.state.books.map((book) => (
                            <BookItem bookdata={book}></BookItem>
                        )) : ''}
                        <button type="button" className="pagination-right" onClick={() => this.goNext()}>›</button>
                    </div>
                </div>
            </div>
        )
    }
}
export default BookList;