import React, { Component } from 'react';

export class Impressum extends Component {
    constructor() {
        super();
    }

    render() {
        return(
            <div className='row'>
                <div className="col-lg-12 col-md-12 col-sm-12 whitebg">
                    <h2>Impresszum</h2>
                    Cégnév: Gáborjányi Benedek EV.<br />
                    Székhely: 1147 Bp, Balázs u. 36.<br />
                    Telephely: 1147 Bp. Balázs u. 36.<br />
                    Telefonszám: +36-70/342-2898<br />
                    E-mail cím: info@konyvkupac.com<br />
                    <br />
                    Internet: konyvkupac.com<br />
                    EV nyilvántartási szám: 56373598<br />
                    Adószám: 57722139-1-42<br />
                    Kamara: Budapesti Kereskedelmi és Iparkamara<br />
                    Tárhelyszolgáltató: DotRoll Kft. (1148 Budapest, Fogarasi út 3-5.) support@dotroll.com<br />
                </div>
            </div>
        )
    }
}
export default Impressum;