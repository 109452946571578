import React, { Component } from 'react';

export class BookItem extends Component {
    render() {
        let kep = "https://konyvkupac.com/image.php/?image="+this.props.bookdata.konyv_kep;
        let fullSizeImage = "https://konyvkupac.com/konyvek/"+this.props.bookdata.konyv_kep;
        return(
            <div className="col-lg-4 col-md-4 col-sm-12 book-item">
                <div className="card">
                    <div className="card-header">{ this.props.bookdata.konyv_szerzo }<br />{ this.props.bookdata.konyv_name }</div>
                    <div className="card-body">
                        <figure>
                            { kep ? <a href={fullSizeImage} target="_blank"><img src={kep} alt={ this.props.bookdata.konyv_name } /></a> : '' }
                        </figure>
                        <small>{ this.props.bookdata.konyv_sorozat }</small>
                    </div>
                    <div className="card-footer">
                        { this.props.bookdata.elerhetoseg === 'Elérhető' ? 
                        <button type="button" className="btn btn-success">{ this.props.bookdata.elerhetoseg }</button> : 
                        <button type="button" className="btn btn-info">{ this.props.bookdata.elerhetoseg }</button> }
                        <span className="right-text">Állapot: { this.props.bookdata.tipus }</span>
                    </div>
                </div>
            </div>
        )
    }
}
export default BookItem;