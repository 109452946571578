import React, { Component } from 'react';

export class Nopage extends Component {
    constructor() {
        super();
    }

    render() {
        return(
            <div className='row'>
                <div className="col-lg-12 col-md-12 col-sm-12 whitebg">
                    <h1>A keresett oldal nem található...</h1>
                </div>
            </div>
        )
    }
}
export default Nopage;