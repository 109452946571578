import React, { Component } from 'react';

export class Gdpr extends Component {
    constructor() {
        super();
    }

    render() {
        return(
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 whitebg">
                    <h1 class="title page-title">Adatvédelmi nyilatkozat - GDPR</h1>
                    <blockquote>
                        <p>
                            A jelen adatvédelmi nyilatkozat (továbbiakban „Nyilatkozat”) tartalmazza a weboldal személyes adatok kezelésére vonatkozó szabályait, valamint az adatkezelésre vonatkozó tájékoztatást. A szolgáltatás használatának megkezdésével a felhasználók elfogadják a jelen Nyilatkozat feltételeit. A jelen adatvédelmi nyilatkozat folyamatosan elérhető az <a href="/gdpr" target="_blank" rel="noopener noreferrer" title="Megnyitás új lapon">Adatvédelmi tájékoztató - GDPR</a> menüpont alatt.
                        </p>
                    </blockquote>

                    <h4>
	1. Az adatkezelő:
</h4>

<p>
	A Szolgáltatással összefüggésben kezelt személyes adatok tekintetében a Szolgáltató nem vesz igénybe adatfeldolgozót. A Szolgáltató által kezelt személyes adatokat kizárólag a Szolgáltató és annak munkavállalói ismerhetik meg. A szolgáltatás felhasználóira vonatkozó adatok kizárólag a Tárhelyszolgáltató szerverein kerülnek tárolásra.
</p>

<h5>
	1.1. Adatkezelő személye:
</h5>

<ul>
	<li><strong>A szolgáltató neve:</strong>&nbsp;Gáborjányi Benedek EV.</li>
	<li><strong>Adatkezelő:</strong>&nbsp;Gáborjányi Benedek EV.</li>
	<li><strong>A szolgáltató székhelye:</strong>&nbsp;1147 Bp. Balázs u. 36.</li>
	<li><strong>EV nyilvántartási szám:</strong>&nbsp;56373598</li>
	<li><strong>Adószáma:</strong>&nbsp;57722139-1-42</li>
	<li>A továbbiakban mint <strong>„Szolgáltató”.</strong></li>
</ul>

<h5>
	1.2. Adatkezelő elérhetősége:
</h5>

<ul>
	<li><strong>Levelezési címe:</strong>&nbsp;1147 Bp. Balázs u. 36.</li>
	<li><strong>Telefon:</strong>&nbsp;+36-70/342-2898</li>
	<li><strong>E-mail:</strong>&nbsp;info@konyvkupac.com</li>
	<li>Az ügyfélszolgálat munkanapon, munkaidőben <strong>10:30&nbsp;- 22:30</strong>&nbsp;között hívható.</li>
</ul>

<h5>
	1.3. Tárhelyszolgáltató adatai:
</h5>

<ul>
	<li><strong>Cégnév:</strong>&nbsp;DotRoll Kft.</li>
	<li><strong>Cím:</strong>&nbsp;1148 Budapest, Fogarasi út 3-5.</li>
	<li><strong>E-mail:</strong>&nbsp;support@dotroll.com</li>
	<li><strong>Weboldal:</strong>&nbsp;www.dotroll.com</li>
</ul>

<p>
	Az Adatfeldolgozó az Adatkezelővel kötött szerződés alapján a személyes adatok tárolását végzi. A személyes adatok megismerésére nem jogosult.
</p>

<h5>
	1.4. Számlázással és könyvelés kapcsolatos adatkezelő:
</h5>

<ul>
	<li><strong>Cégnév:</strong> KBOSS.hu Kft.</li>
	<li><strong>Cím:</strong> 1031 Budapest, Záhony utca 7.</li>
	<li><strong>Cégjegyzékszám:</strong> 01-09-303201</li>
	<li><strong>Adószám:</strong> 13421739-2-41</li>
	<li><strong>E-mail:</strong> <a rel="noopener noreferrer" href="info@szamlazz.hu" target="_blank">info@szamlazz.hu</a></li>
	<li><strong>Weboldal:</strong> <a rel="noopener noreferrer" href="https://www.szamlazz.hu">www.szamlazz.hu</a></li>
</ul>

<p>
	</p><p>Az Adatfeldolgozó az Adatkezelővel kötött írásbeli szerződés alapján közreműködik a számlviteli bizonylatok könyvelésében. Ennek során az Adatfeldolgozó az érintett nevét és címét a számviteli nyilvántartáshoz szükséges mértékben, a Sztv. 169. § (2) bekezdésének megfelelő időtartamban kezeli.
</p>

<h4>
	2. Kezelt adatok köre és céljai:
</h4>

<h5>
	2.1. A szerződéskötés és teljesítés érdekében kezelt adatok:
</h5>

<p>
	A szerződéskötés és teljesítés érdekében több adatkezelési eset is megvalósulhat. Tájékoztatunk, hogy panaszkezeléssel, garanciális ügyintézéssel kapcsolatos adatkezelés csak abban az esetben valósul meg, ha élsz valamelyik említett jogoddal.
</p>

<p>
	Amennyiben a webáruházon keresztül nem vásárolsz, csak látogatója vagy a webáruháznak, úgy csak a marketing célú adatkezelésnél írtak vonatkozhatnak rád, amennyiben marketing célú hozzájárulást adsz számunkra.
</p>

<p>
	A hibásan megadott vagy hiányos adatokból eredő kellemetlenségekért, vagy ilyen okokból a teljesítés elmaradásáért felelősséget vállalni nem tudunk.
</p>

<h6>
	2.1.1. Regisztráció
</h6>

<p>
	A regisztráció során megadott adatok tárolásával az Adatkezelő kényelmesebb szolgáltatást tud biztosítani (pl. az érintett adatait újabb vásárláskor nem kell ismét megadni). A regisztráció a vásárlásnak és ezáltal a szerződéskötésnek nem feltétele.
</p>

<p>
	Az adatok kérése során az Üzemeltető egyértelműen jelzi a kötelezően és opcionálisan megadandó adatokat.
</p>

<p>
	Adatkezelő a birtokába jutott adatokat az 1992. évi „LXIII. Személyes adatok védelméről” szóló és az 1995. évi CXIX. „Kutatás és közvetlen üzletszerzés”-ről szóló törvények előírásainak megfelelően kezeli.
</p>

<ul>
	<li><strong>Kezelt adatok köre:</strong> név, lakcím, telefonszám, e-mail cím, a megvásárolt termék(ek) jellemzői és a vásárlás időpontja</li>
	<li><strong>Az adatkezelés jogalapja:</strong> az önkéntes hozzájárulásod, amit a regisztrációval adsz meg az Adatkezelő számára</li>
	<li><strong>Az adatkezelés időtartama:</strong> a hozzájárulás visszavonásáig</li>
</ul>

<h6>
	2.1.2. Rendelés feldolgozás
</h6>

<p>
	A rendelések feldolgozása során a szerződés teljesítése érdekében szükségesek adatkezelési tevékenységek.
</p>

<p>
	Amennyiben rendelést adtál le a webáruházban, az adatkezelés és az adatok megadása a szerződés teljesítéséhez elengedhetetlen.
</p>

<ul>
	<li><strong>Kezelt adatok köre:</strong> név, lakcím, telefonszám, e-mail cím, megrendelés száma, megvásárolt termék(ek) jellemzői és a vásárlás időpontja</li>
	<li><strong>Az adatkezelés jogalapja:</strong> a szerződés teljesítése</li>
	<li><strong>Az adatkezelés időtartama:</strong> az adatokat a polgári jogi elévülési idő szerint 5 évig kezeljük</li>
</ul>

<h6>
	2.1.3. Könyvelés, számlázás
</h6>

<p>
	Az adatkezelési folyamat a jogszabályoknak megfelelő számla kiállítása és a számviteli bizonylat-megőrzési kötelezettség teljesítése érdekében történik. Az Sztv. 169. § (1)-(2) bekezdése alapján a gazdasági társaságoknak a könyvviteli elszámolást közvetlenül és közvetetten alátámasztó számviteli bizonylatot meg kell őrizniük.
</p>

<ul>
	<li><strong>Kezelt adatok köre:</strong> név, lakcím, telefonszám, e-mail cím, megrendelés száma, megvásárolt termék(ek) jellemzői és a vásárlás időpontja</li>
	<li><strong>Az adatkezelés jogalapja:</strong> az általános forgalmi adóról szóló 2007. évi CXXVII. 159. § (1) bekezdése alapján a számla kibocsátása kötelező és azt számvitelről szóló 2000. évi C. törvény 169. § (2) bekezdése alapján 8 évig kell megőrizni</li>
	<li><strong>Az adatkezelés időtartama:</strong> a kiállított számlákat az Sztv. 169. § (2) bekezdése alapján a számla kiállításától számított 8 évig meg kell őrizni</li>
</ul>

<h6>
	2.1.4. Szavatossági és Jótállási igények
</h6>

<p>
	A szavatossági és jótállási igények kezelése során a 19/2014. (IV. 29.) NGM rendelet szabályai szerint járunk el.
</p>

<ul>
	<li><strong>Kezelt adatok köre:</strong>
		<ul>
			<li>név, lakcím, valamint nyilatkozatát arról, hogy hozzájárulsz a jegyzőkönyvben rögzített adataidnak a rendeletben meghatározottak szerinti kezeléséhez</li>
			<li>a közted és a Szolgáltató közt létrejött szerződés keretében eladott ingó dolog megnevezése, vételára</li>
			<li>a szerződés teljesítésének időpontja</li>
			<li>a hiba bejelentésének időpontja</li>
			<li>a hiba leírása</li>
			<li>szavatossági vagy jótállási igényed alapján az érvényesíteni kívánt jog</li>
			<li>a szavatossági vagy jótállási igény rendezésének módja vagy az igény, illetve az az alapján érvényesíteni kívánt jog elutasításának indoka</li>
		</ul>
	</li>
	<li><strong>Az adatkezelés jogalapja:</strong> a 19/2014. (IV. 29.) NGM rendelet 4. § (1) bekezdés és 6. § (1) bekezdés szerinti jogi kötelezettségeknek való megfelelés</li>
	<li><strong>Az adatkezelés időtartama:</strong> a szolgáltató a fogyasztó szavatossági vagy jótállási igényéről felvett jegyzőkönyvet az annak felvételétől számított három évig köteles megőrizni, és azt az ellenőrző hatóság kérésére bemutatni</li>
</ul>

<h6>
	2.1.5. Egyéb fogyasztóvédelmi panaszok
</h6>

<p>
	Az adatkezelési folyamat a fogyasztóvédelmi panaszok kezelése érdekében történik. Amennyiben panasszal fordultál hozzánk, az adatkezelés elengedhetetlen.
</p>

<ul>
	<li><strong>Kezelt adatok köre:</strong> név, e-mail cím, telefonszám, panasz tartalma</li>
	<li><strong>Az adatkezelés jogalapja:</strong> az, hogy hozzánk fordulsz-e panasszal az a te önkéntes döntésed, azonban ha hozzánk fordulsz, a fogyasztóvédelemről szóló 1997. évi CLV. törvény 17/A. § (7) bekezdése alapján 5 évig kötelesek vagyunk a panaszt megőrizni</li>
	<li><strong>Az adatkezelés időtartama:</strong> a garanciális panaszokat a fogyasztóvédelemről szóló törvény alapján 5 évig őrizzük meg</li>
</ul>

<h6>
	2.1.6. Hozzájárulások naplózása, igazolhatósága
</h6>

<p>
	A regisztráció, a megrendelés, a kapcsolatfelvétel, valamint a hírlevélre történő feliratkozás során az informatikai rendszer eltárolja a hozzájárulással kapcsolatos informatikai adatokat a későbbi bizonyíthatóság érdekében.
</p>

<ul>
	<li><strong>Kezelt adatok köre:</strong> név, e-mail cím, szöveges tartalom, elfogadás nyelve, IP cím, elfogadás dátuma, elfogadott nyilatkozatok tartalma az elfogadás pillanatában</li>
	<li><strong>Az adatkezelés jogalapja:</strong> A GDPR rendelet 7. cikk (1) bekezdése</li>
	<li><strong>Az adatkezelés időtartama:</strong> a jogszabályi előírások miatt a hozzájárulást később igazolni kell tudni, ezért az adattárolás időtartama az adatkezelés megszűnését követő elévülési ideig kerül tárolásra</li>
</ul>

<h5>
	2.2. Marketing céllal kezelt adatok:
</h5>

<h6>
	2.2.1. Hírlevél küldés
</h6>

<p>
	Az adatkezelés a hírlevelek kiküldése érdekében történik.
</p>

<ul>
	<li><strong>Kezelt adatok köre:</strong> név, e-mail cím</li>
	<li><strong>Az adatkezelés jogalapja:</strong> a hozzájárulás visszavonásáig</li>
	<li><strong>Az adatkezelés időtartama:</strong> a te önkéntes hozzájárulásod, ami a hírlevélre történő feliratkozással adsz meg az Adatkezelő számára</li>
</ul>

<p>
	Az Adatfeldolgozó az Adatkezelővel kötött szerződés alapján közreműködik a hírlevelek kiküldésében. Ennek során az Adatfeldolgozó az érintett nevét és e-mail címét a hírlevélküldéshez szükséges mértékben kezeli:
</p>

<ul>
	<li><strong>Cégnév:</strong>&nbsp;DotRoll Kft.</li><li><strong>Cím:</strong>&nbsp;1148 Budapest, Fogarasi út 3-5.</li><li><strong>E-mail:</strong>&nbsp;support@dotroll.com</li><li><strong>Weboldal:</strong>&nbsp;www.dotroll.com</li>
</ul>

<h6>
	2.2.2. Személyre szabott ajánlatok
</h6>

<p>
	Az adatkezelési folyamat az érintett érdeklődési körének megfelelő tartalmú reklámtartalom kiküldésének és megjelenítésének érdekében történik.
</p>

<ul>
	<li><strong>Kezelt adatok köre:</strong> név, e-mail cím, lakcím, telefonszám</li>
	<li><strong>Az adatkezelés jogalapja:</strong> a hozzájárulás visszavonásáig</li>
	<li><strong>Az adatkezelés időtartama:</strong> a te önkéntes, külön hozzájárulásod, amit az adatfelvétel során adsz meg az Adatkezelő számára</li>
</ul>

<h6>
	2.2.3. Remarketing
</h6>

<p>
	Az adatkezelés mint remarketing tevékenység cookie-k segítségével valósul meg. A sütikről részletesen az Adatvédelmi nyilatkozat 3. pontja ad tájékoztatást.
</p>

<ul>
	<li><strong>Kezelt adatok köre:</strong> a cookie tájékoztatóban meghatározott cookiek által kezelt adatok</li>
	<li><strong>Az adatkezelés jogalapja:</strong> az te önkéntes hozzájárulásod, amit a honlap használatával adsz meg az Adatkezelő számára</li>
	<li><strong>Az adatkezelés időtartama és részletes tájékoztató:</strong>
		<ul>
			<li><a href="https://www.google.com/policies/technologies/types/" target="_blank" rel="noreferrer noopener">Google általános cookie tájékoztató</a></li>
			<li><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=hu" target="_blank" rel="noreferrer noopener">Google Analitycs tájékoztató</a></li>
			<li><a href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen" target="_blank" rel="noreferrer noopener">Facebook tájékoztató</a></li>
		</ul>
	</li>
</ul>

<h5>
	2.3. Az adatkezeléssel kapcsolatos jogaid:
</h5>

<p>
	Az adatkezelés időtartamán belül a GDPR rendelet előírásai szerint az alábbi jogok illetnek meg:
</p>

<ul>
	<li>a hozzájárulás visszavonásának joga</li>
	<li>személyes adatokhoz és az adatkezeléssel kapcsolatos információkhoz való hozzáférés</li>
	<li>helyesbítéshez való jog</li>
	<li>adatkezelés korlátozása</li>
	<li>törléshez való jog</li>
	<li>tiltakozáshoz való jog</li>
	<li>hordozhatósághoz való jog</li>
</ul>

<p>
	Amennyiben élni kívánsz a jogaiddal, az az azonosításoddal együtt jár, valamint az Adatkezelőnek szükségszerűen kommunikálnia kell veled. Ezért az azonosítás érdekében személyes adatok megadására lesz szükség (az azonosítás csak olyan adaton alapulhat, melyet az Adatkezelő egyébként is kezel rólad). Amennyiben vásárlónk vagy és panaszügyintézés, vagy garanciális ügyintézés érdekében szeretnéd magad azonosítani, kérjük add meg rendelési azonosítót is. Ennek felhasználásával mint vásárlót tudunk azonosítani.
</p>

<p>
	Az adatkezeléssel kapcsolatos panaszokat legkésőbb 30 napon belül válaszolja meg Adatkezelő.
</p>

<h6>
	2.3.1. A hozzájárulás visszavonásának joga
</h6>

<p>
	Bármikor jogosult vagy az adatkezeléshez adott hozzájárulásodat visszavonni, ilyen esetben a megadott adatokat rendszereinkből töröljük. Kérjük, vedd figyelembe, hogy ha már vásároltál nálunk, a számviteli előírások alapján a számlázással kapcsolatos adatokat nem törölhetjük rendszereinkből, valamint ha tartozásod áll fenn felénk, akkor a követelés behajtásával kapcsolatos jogos érdek alapján adataidat a hozzájárulás visszavonása esetén is kezelhetjük.
</p>

<h6>
	2.3.2. A személyes adatokhoz való hozzáférés
</h6>

<p>
	Jogosult vagy arra, hogy az Adatkezelőtől visszajelzést kérj arra vonatkozóan, hogy személyes adataidnak kezelése folyamatban van-e, és ha igen, jogosult vagy, hogy:
</p>

<ul>
	<li>a kezelt személyes adatokhoz hozzáférj</li>
	<li>tájékoztatást kérj Adatkezelőtől a következő információkról:</li>
		<ul>
			<li>az adatkezelés céljairól</li>
			<li>a rólad kezelt személyes adatok kategóriáiról</li>
			<li>információ azon személyekről és cégekről, akivel a kezelt adatokat az Adatkezelő közölni fogja</li>
			<li>a személyes adatok tárolásának tervezett időtartamáról, vagy ha ez nem lehetséges, ezen időtartam meghatározásának szempontjairól</li>
			<li>kérelmezheted az Adatkezelőtől a rád vonatkozó személyes adatok helyesbítését, törlését vagy kezelésének korlátozását, és jogos érdeken alapuló adatkezelés esetén tiltakozhatsz az ilyen személyes adatok kezelése ellen</li>
			<li>a felügyeleti hatósághoz címzett panasz benyújtásának jogáról</li>
			<li>ha az adatokat nem tőled gyűjtötték be, a forrásukra vonatkozó minden elérhető információról</li>
			<li>az automatizált döntéshozatal tényéről, ideértve a profilalkotást is, valamint legalább ezekben az esetekben az alkalmazott logikára és arra vonatkozóan érthető információkat, hogy az ilyen adatkezelés milyen jelentőséggel, és rád nézve milyen várható következményekkel jár</li>
		</ul>
	
</ul>

<p>
	A jog gyakorlásának célja az adatkezelés jogszerűségének megállapítására és ellenőrzésére irányulhat, ezért többszöri tájékoztatás kérés esetén Adatkezelő méltányos költségtérítést számolhat fel a tájékoztatás teljesítéséért cserébe.
</p>

<p>
	A személyes adatokhoz való hozzáférést Adatkezelő úgy biztosítja, hogy az azonosítást követően emailben juttatja el a kezelt személyes adatokat és az információkat. Amennyiben regisztrációval rendelkezel, a felhasználói fiókodban található GDPR menüpontban megtalálod az összes rólad kezelt adatot, nem szükséges külön kérned azokat az Adatkezelőtől.
</p>

<p>
	Kérjük, hogy a kérelmedben jelöld meg, hogy a személyes adatokhoz kérsz hozzáférést, vagy az adatkezeléssel kapcsolatos információkat szeretnél kérni.
</p>

<h6>
	2.3.3. A személyes adatokhoz való hozzáférés
</h6>

<p>
	Jogosult vagy arra, hogy kérésére az Adatkezelő késedelem nélkül helyesbítse az rád vonatkozó pontatlan személyes adatokat.
</p>

<h6>
	2.3.4. Adatkezelés korlátozásához való jog
</h6>

<p>
	Jogosult vagy arra, hogy kérésede Adatkezelő korlátozza az adatkezelést, ha az alábbiak valamelyike teljesül:
</p>

<ul>
	<li>vitatod a személyes adatok pontosságát, ez esetben a korlátozás arra az időtartamra vonatkozik, amely lehetővé teszi, hogy Adatkezelő ellenőrizze a személyes adatok pontosságát; ha a pontos adat azonnal megállapítható, akkor nem kerül sor a korlátozásra</li>
	<li>az adatkezelés jogellenes, de ellenzed az adatok törlését bármely okból (például azért mert azok számodra jogi igény érvényesítése miatt fontosak az adatok), ezért nem az adatok törlését kéred, hanem azok felhasználásának korlátozását</li>
	<li>az Adatkezelőnek már nincs szüksége a személyes adatokra a megjelölt adatkezelés céljából, de te igényled azokat jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez</li>
	<li>tiltakoztál az adatkezelés ellen, de Adatkezelő jogos érdeke is megalapozhatja az adatkezelést, ez esetben amíg megállapításra nem kerül, hogy Adatkezelő jogos indokai elsőbbséget élveznek-e a te jogos indokaiddal szemben, az adatkezelést korlátozni kell</li>
</ul>

<p>
	Ha az adatkezelés korlátozás alá esik, az ilyen személyes adatokat a tárolás kivételével csak az érintett hozzájárulásával, vagy jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez, vagy más természetes vagy jogi személy jogainak védelme érdekében, vagy az Európai Unió, illetve valamely tagállam fontos közérdekéből lehet kezelni.
</p>

<p>
	Adatkezelő az adatkezelés korlátozásának feloldásáról előzetesen legalább a korlátozás feloldását megelőző 3 munkanappal tájékoztatja az érintetteket.
</p>

<h6>
	2.3.5. Törléshez - elfeledtetéshez való jog
</h6>

<p>
	Jogosult vagy arra, hogy Adatkezelő indokolatlan késedelem nélkül törölje a rád vonatkozó személyes adatokat, ha az alábbi indokok valamelyike fennáll:
</p>

<ul>
	<li>a személyes adatokra már nincs szükség abból a célból, amelyből azokat Adatkezelő gyűjtötte vagy más módon kezelte</li>
	<li>visszavonod hozzájárulását és az adatkezelésnek nincs más jogalapja</li>
	<li>tiltakozol a jogos érdeken alapuló adatkezelés ellen, és nincs elsőbbséget élvező jogszerű ok (azaz jogos érdek) az adatkezelésre</li>
	<li>a személyes adatokat Adatkezelő jogellenesen kezelte és ez a panasz alapján megállapítást nyert</li>
	<li>a személyes adatokat az Adatkezelőre alkalmazandó uniós vagy tagállami jogban előírt jogi kötelezettség teljesítéséhez törölni kell</li>
</ul>

<p>
	Ha az Adatkezelő bármely jogszerű oknál fogva nyilvánosságra hozta a rólad kezelt személyes adatokat, és bármely fent megjelölt okból törölni köteles azt, az elérhető technológia és a megvalósítás költségeinek figyelembevételével köteles megtenni az észszerűen elvárható lépéseket – ideértve technikai intézkedéseket – annak érdekében, hogy tájékoztassa az adatokat kezelő más adatkezelőket, hogy kérelmezted a szóban forgó személyes adatokra mutató linkek vagy e személyes adatok másolatának, illetve másodpéldányának törlését.
</p>

<p>
	A törlés nem alkalmazandó, amennyiben az adatkezelés szükséges:
</p>

<ul>
	<li>a véleménynyilvánítás szabadságához és a tájékozódáshoz való jog gyakorlása céljából</li>
	<li>a személyes adatok kezelését előíró, az adatkezelőre alkalmazandó európai uniós vagy tagállami jog szerinti kötelezettség teljesítése (ilyen eset a számlázás keretében megvalósuló adatkezelés, mivel a számla megőrzését jogszabály írja elő), illetve közérdekből vagy az adatkezelőre ruházott közhatalmi jogosítvány gyakorlása keretében végzett feladat végrehajtása céljából</li>
	<li>jogi igények előterjesztéséhez, érvényesítéséhez, illetve védelméhez (pl.: ha Adatkezelőnek követelése áll fenn feléd és azt még nem teljesítetted, vagy fogyasztói, adatkezelési panasz intézése van folyamatban)</li>
</ul>

<h6>
	2.3.6. Tiltakozáshoz való jog
</h6>

<p>
	Jogosult vagy arra, hogy a saját helyzeteddel kapcsolatos okokból bármikor tiltakozz a személyes adataid jogos érdeken alapuló kezelése ellen. Ebben az esetben Adatkezelő a személyes adatokat nem kezelheti tovább, kivéve, ha az bizonyítja, hogy az adatkezelést olyan kényszerítő erejű jogos okok indokolják, amelyek elsőbbséget élveznek a te érdekeiddel, jogaiddal és szabadságaiddal szemben, vagy amelyek jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez kapcsolódnak.
</p>

<p>
	Ha a személyes adatok kezelése közvetlen üzletszerzés érdekében történik, jogosult vagy arra, hogy bármikor tiltakozz a rád vonatkozó személyes adatok e célból történő kezelése ellen, ideértve a profilalkotást is, amennyiben az a közvetlen üzletszerzéshez kapcsolódik. Ha tiltakozol a személyes adatok közvetlen üzletszerzés érdekében történő kezelése ellen, akkor a személyes adatok a továbbiakban e célból nem kezelhetők.
</p>

<h6>
	2.3.7. Hordozhatósághoz való jog
</h6>

<p>
	Amennyiben az adatkezelés automatizált módon valósul meg, vagy ha az adatkezelés a te önkéntes hozzájárulásodon alapul, jogod van kérni az Adatkezelőtől, hogy az általad az Adatkezelő részére megadott adatokat megkapd, amit Adatkezelő XLS, XLSX vagy CSV formátumban bocsát rendelkezésedre. Ha ez technikailag megvalósítható, akkor kérheted, hogy az Adatkezelő az adatokat ebben a formában más adatkezelő számára továbbítsa.
</p>

<h5>
	2.4. Adatbiztonsági intézkedések
</h5>

<p>
	Az Adatkezelő kijelenti, hogy megfelelő biztonsági intézkedéseket hozott annak érdekében, hogy a személyes adatokat védje a jogosulatlan hozzáféréstől, megváltoztatástól, továbbítástól, nyilvánosságra hozataltól, törléstől vagy megsemmisítéstől, valamint a véletlen megsemmisüléstől és sérüléstől, továbbá az alkalmazott technika megváltozásából fakadó hozzáférhetetlenné válástól.
</p>

<p>
	Az Adatkezelő a szervezeti és technikai lehetőségekhez képest mindent megtesz annak érdekében, hogy adatfeldolgozói is megfelelő adatbiztonsági intézkedéseket tegyenek, amikor a személyes adataiddal dolgoznak.
</p>

<h5>
	2.5. Jogorvoslati lehetőségek
</h5>

<p>
	Amennyiben szerinted az Adatkezelő megsértette valamely, az adatkezelésre vonatkozó törvényi rendelkezést, vagy nem teljesítette valamely kérelmedet, akkor a vélelmezett jogellenes adatkezelés megszüntetése érdekében a Nemzeti Adatvédelmi és Információszabadság Hatóság vizsgálati eljárását kezdeményezheted:
</p>

<ul>
	<li><strong>Levelezési cím:</strong> 1363 Budapest, Pf. 9.</li>
	<li><strong>E-mail:</strong> <a href="mailto:ugyfelszolgalat@naih.hu" target="_blank" rel="noreferrer noopener">ugyfelszolgalat@naih.hu</a></li>
</ul>

<p>
	Tájékoztatunk emellett arról is, hogy az adatkezelésre vonatkozó törvényi rendelkezések megsértése esetén, vagy ha az Adatkezelő nem teljesítette valamely kérelmedet, akkor az Adatkezelővel szemben polgári pert indíthatsz bíróság előtt. 
</p>

<h5>
	2.6. Automatizált döntéshozatal
</h5>

<p>
	Jogosult vagy arra, hogy ne terjedjen ki rád az olyan, kizárólag automatizált adatkezelésen alapuló döntés hatálya (ideértve a profilalkotást is), amely rád nézve joghatással járna vagy hasonlóképpen jelentős mértékben érintene. Ezekben az esetekben Adatkezelő köteles megfelelő intézkedéseket tenni az érintett jogainak, szabadságainak és jogos érdekeinek védelme érdekében, ideértve az érintettnek legalább azt a jogát, hogy az adatkezelő részéről emberi beavatkozást kérjen, álláspontját kifejezze, és a döntéssel szemben kifogást nyújtson be.
</p>

<p>
	A fentiek nem alkalmazandóak abban az esetben, ha a döntés:
</p>

<ul>
	<li>közted és az adatkezelő közti szerződés megkötése vagy teljesítése érdekében szükséges</li>
	<li>meghozatalát az adatkezelőre alkalmazandó olyan európai uniós vagy tagállami jog teszi lehetővé, amely a te jogainak és szabadságaidnak, valamint jogos érdekeidnek védelmét szolgáló megfelelő intézkedéseket is megállapít</li>
	<li>az te kifejezett hozzájárulásodon alapul</li>
</ul>

<h4>
	3. Cookie-k (sütik)
</h4>

<h5>
	3.1. A cookie (süti) meghatározása:
</h5>

<p>
	Az Adatkezelő a honlap látogatása során úgynevezett cookie-kat használ. A cookie betűből és számokból álló információcsomag, amit honlapunk a böngésződnek küld el azzal a céllal, hogy elmentse bizonyos beállításaidat, megkönnyítse a honlapunk használatát és közreműködik abban, hogy néhány releváns, statisztikai jellegű információt gyűjtsünk a látogatóinkról.
</p>

<p>
	A cookie-k egy része nem tartalmaz személyes információkat, és nem alkalmas az egyéni felhasználó azonosítására, egy részük azonban olyan egyéni azonosítót tartalmaz - egy titkos, véletlenül generált számsort - amelyet a te eszközöd tárol, ezzel a te azonosíthatóságodat is biztosítja. Az egyes cookie-k működési időtartamát az egyes cookie-k vonatkozó leírása tartalmazza.
</p>

<p>
	<strong>
		Az adatkezelés jogalapja a GDPR Rendelet 6. cikk (1) bekezdés a) pontja alapján a te hozzájárulásod.
	</strong>
</p>

<h5>
	3.2. A cookie-k típusai:
</h5>

<h6>
	3.2.1. A működéshez szigorúan szükséges cookie-k:
</h6>

<p>
	Ezek a cookie-k a weboldal használatához nélkülözhetetlenek, és lehetővé teszik a weboldal alapvető funkcióinak használatát. Ezek hiányába az oldal számos funkciója nem lesz elérhető a számodra. Ezen típusú cookie-k élettartama kizárólag a munkamenet idejére korlátozódik.
</p>

<p>
	Ezen cookie-k közé tartoznak:
</p>

<ul>
	<li><strong>Cookie elfogadás cookie:</strong> az oldalra érkezéskor a figyelmeztető ablakban elfogadja a cookiek tárolásáról szóló nyilatkozatot. Élettartama 365 nap</li>
	<li><strong>Kosár cookie:</strong> a kosárba helyezett termékeket rögzíti. Élettartama 365 nap</li>
	<li><strong>Backend azonosító cookie:</strong> az oldalt kiszolgáló backend szerver azonosítója. Élettartama a böngésző bezárásáig tart</li>
	<li><strong>Google ReCaptcha:</strong> szolgáltatásaiban a ReCaptcha-funkciót használja. A ReCaptcha használatára a Google <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noopener noreferrer" title="Megnyitás új lapon">adatvédelmi irányelvei</a> és <a href="https://policies.google.com/terms?hl=en" target="_blank" rel="noopener noreferrer" title="Megnyitás új lapon">használati feltételei</a> vonatkoznak. A ReCaptcha szolgáltatást csak a levélszemét és visszaélések elleni küzdelemre használjuk</li>
	<li><strong>Munkamenet cookie:</strong> ezek a sütik a látogató tartózkodási helyét, a böngésző nyelvét, a fizetés pénznemét tárolják, élettartamuk a böngésző bezárása, vagy maximum 2 óra</li>
	<li><strong>Mobil verzió, design cookie:</strong> érzékeli a látogató használt eszközt, és mobilon átvált teljes nézetre. Élettartama 365 nap</li>
	<li><strong>employee_login_last_email:</strong> bejelentkezésnél az emailcímet tárolja a böngésző bezárásáig</li>
	<li><strong>come_from:</strong> beléptetés átirányítást végez. Élettartama 10 perc</li>
</ul>

<h6>
	3.2.2. Analitika, statisztika és felhasználói élmény javítását szolgáló cookie-k:
</h6>

<p>
	Ezek a cookie-k információkat gyűjtenek a felhasználó weboldalhasználatáról, például, hogy mely oldalakat látogatja leggyakrabban, vagy milyen hibaüzenetet kap a weboldalról. Ezek a cookie-k nem gyűjtenek a látogatót azonosító információkat, vagyis teljesen általános, névtelen információkkal dolgoznak. Az ezekből nyert adatokat a weboldal teljesítményének javítására használjuk. Ezen típusú cookie-k élettartama kizárólag a munkamenet idejére korlátozódik.
</p>

<p>
	Ezen cookie-k közé tartoznak:
</p>

<ul>
	<li><strong>Referer cookie-k:</strong> azt rögzítik, hogy a látogató milyen külső oldalról érkezett az oldalra. Élettartamuk a böngésző bezárásáig tart</li>
	<li><strong>Kiléptetés #2 cookie:</strong> a #2 opció szerint 90 nap után kilépteti a rendszer a látogatót. Élettartama 90 nap</li>
	<li><strong>Ealrm, ealem, ealpw:</strong> permanens beléptetést biztosít. Élettartama 180 nap</li>
	<li><strong>currency:</strong> a vásárló pénznemét tárolja. Élettartama 30 nap</li>
</ul>

<h6>
	3.2.3. Marketing és Remarketing cookie-k:
</h6>

<p>
	A marketing sütik teszik lehetővé, hogy a vásárlók viselkedését elemezve olyan reklámkampányokat hozzunk létre, melyek a megfelelő célközönséghez jutnak el.
</p>

<p>
	Ezen cookie-k közé tartoznak:
</p>

<ul>
	<li><strong>Google Adwords cookie:</strong> amikor valaki meglátogatja az oldalunkat, a látogató cookie-azonosítója hozzáadódik a remarketinglistához. A Google cookie-kat – például a NID és SID cookie-kat – használ a Google-termékekben, így például a Google Keresésben látható hirdetések testreszabásához. Az ilyen cookie-kat például arra használja, hogy megjegyezze a legutóbbi kereséseidet, az egyes hirdetők hirdetéseivel vagy a keresési eredményekkel való korábbi interakciókat, továbbá a hirdetők webhelyein tett látogatásait. Az AdWords konverziókövetés funkciója cookie-kat használ. A hirdetésből eredő értékesítések és egyéb konverziók követésére cookie-kat ment a felhasználó számítógépére, amikor az adott személy egy hirdetésre kattint. A cookie-k néhány gyakori alkalmazási módja: a hirdetések kiválasztása annak alapján, hogy mi a releváns az adott felhasználó esetén, a kampányok teljesítményéről szóló jelentések tökéletesítése, és a felhasználó által már megtekintett hirdetések megjelenítésének elkerülése.</li>
	<li><strong>Google Analytics cookie:</strong> a Google Analytics a Google elemző eszköze, amely abban segít a weboldalak és alkalmazások tulajdonosainak, hogy pontosabb képet kapjanak látogatóik tevékenységeiről. A szolgáltatás cookie-kat használhat, hogy információkat gyűjtsön és jelentést készítsen a weboldal használatára vonatkozó statisztikai adatokból anélkül, hogy egyénileg azonosítaná a látogatókat a Google számára. A Google Analytics által használt fő cookie a „__ga” cookie. A webhelyhasználati statisztikai adatokból készülő jelentések mellett a Google Analytics – az előbbiekben ismertetett néhány hirdetési cookie-val együtt – felhasználható arra is, hogy relevánsabb hirdetéseket jelenítsünk meg a Google-termékekben (például a Google Keresésben) és szerte az interneten.</li>
	<li><strong>Remarketing cookiek-k:</strong> a korábbi látogatók vagy felhasználók számára a Google Display Hálózaton található egyéb webhelyeken való böngészés, illetve termékeivel vagy szolgáltatásaival kapcsolatos kifejezések keresésekor jelenhetnek meg </li>
	<li><strong>Utoljára megtekintett termék cookie:</strong> rögzíti a termékeket, amiket utoljára megtekintett a látogató. Élettartamuk 60 nap.</li>
	<li><strong>Utoljára megtekintett kategória cookie:</strong> rögzíti az utoljára megtekintett kategóriát. Élettartama 60 nap.</li>
	<li><strong>Ajánlott termékek cookie:</strong> a „termékajánló” funkciónál az ajánlani kívánt termékek listáját rögzíti. Élettartama 60 nap.</li>
	<li><strong>predictionio:</strong> felhasználó azonosító cookie személyre szabott hirdetések ajánlásához. Élettartama 3 hónap.</li>
	<li><strong>Intelligens ajánlat cookie:</strong> intelligens ajánlatok megjelenítésének feltételeit rögzíti (pl. volt-e már az oldalon a látogató, van-e rendelése). Élettartama 30 nap.</li>
	<li><strong>Facebook pixel (Facebook cookie):</strong> a Facebook-pixel olyan kód, amelynek a segítségével a honlapon jelentés készül a konverziókról, célközönségek állíthatók össze, és az oldal tulajdonosa részletes elemzési adatokat kap a látogatók honlap használatáról. A Facebook pixel segítségével a weboldal látogatóinak személyre szabott ajánlatokat, hirdetéseket jeleníthet meg a Facebook felületén. A Facebook adatkezelési szabályzatát itt tanulmányozhatod: <a href="https://www.facebook.com/privacy/explanation" target="_blank" rel="noreferrer noopener">Facebook adatkezelés</a></li>
</ul>

<h5>
	3.3. A cookie-k törlése:
</h5>

<p>
	A cookie-k törléséről bővebb tájékoztatást az alábbi linkeken találhatsz:
</p>

<ul>
	<li><a href="https://windows.microsoft.com/en-us/internet-explorer/delete-manage-cookies#ie=ie-11" target="_blank" rel="noreferrer noopener">Internet Explorer</a></li>
	<li><a href="https://support.mozilla.org/hu/kb/weboldalak-altal-elhelyezett-sutik-torlese-szamito" target="_blank" rel="noreferrer noopener">Firefox</a></li>
	<li><a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank" rel="noreferrer noopener">Safari</a></li>
	<li><a href="https://support.google.com/chrome/answer/95647" target="_blank" rel="noreferrer noopener">Chrome</a></li>
	<li><a href="https://support.microsoft.com/hu-hu/help/4027947/microsoft-edge-delete-cookies" target="_blank" rel="noreferrer noopener">Edge</a></li>
</ul>

<h4>
	4. Módosítás
</h4>

<p>
	Üzemeltető fenntartja a jogot a jelen adatvédelmi nyilatkozat módosítására, ekkor a módosított nyilatkozatot elérhetővé teszi a Szolgáltatás nyitóoldalán.
</p>
                </div>
            </div>
        )
    }
}
export default Gdpr;