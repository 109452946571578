import { BrowserRouter, Routes, Route } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import BookList from './components/listing/book-list';
import Nopage from "./components/nopage/nopage";
import Gdpr from "./components/gdpr/gdpr";
import Aszf from "./components/aszf/aszf";
import Impressum from "./components/impresszum/Impressum";
import Layout from "./components/Layout";
import React, { Component } from 'react';

export class App extends Component {
    constructor() {
        super();
    }

    render() {
      return (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={ <BookList />} />
              <Route path="/impresszum" element={<Impressum />} />
              <Route path="/gdpr" element={<Gdpr />} />
              <Route path="/aszf" element={<Aszf />} />
              <Route path="*" element={<Nopage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      )    
    }
}
export default App;
