import React, { Component } from 'react';

export class Aszf extends Component {
    constructor() {
        super();
    }

    render() {
        return(
            <div className='row'>
                <div className="col-lg-12 col-md-12 col-sm-12 whitebg">
<h2>Általános szerződési feltételek (ÁSZF)</h2>
Jelen dokumentum tartalmazza a Gáborjányi Benedek EV., mint Szolgáltató által üzemeltetett konyvkupac.com (a továbbiakban: webáruház) használatára vonatkozó általános szerződési feltételeket (továbbiakban „ÁSZF”).<br />
<br />
A létrejött szerződés elektronikus úton megkötött szerződésnek minősül, amelyre az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről szóló 2001. évi CVIII. törvény 5. és 6. §-ában, a Polgári Törvénykönyv XVI. fejezetében, valamint a fogyasztó és a vállalkozás közötti szerződések részletes szabályairól szóló 45/2014. (II. 26.) Korm. rendeletben foglaltak az irányadóak. Az elektronikus úton létrejött szerződés írásbeli szerződésnek minősül, melyet külön nem iktatunk, kizárólag elektronikus formában érhető el a továbbiakban a weboldalon keresztül (Az Általános szerződési feltételek menüpontban, valamint vásárlás során a GDPR rendelkezéseinek megfelelően tároljuk a dokumentum elfogadáskori állapotát, melyet GDPR adatigényléskor rendelkezésre bocsátunk.), továbbá semmilyen magatartási kódex rendelkezéseinek nem vetjük alá magunkat. Szerződés nyelve: magyar. A webáruház használata során köteles vagy gondoskodni arról, hogy harmadik személyek jogait vagy a jogszabályokat se közvetlenül, se közvetett módon ne sértsd meg.<br />
<br />
Kérjük, hogy csak akkor vedd igénybe szolgáltatásainkat, amennyiben minden pontjával egyetértesz, és kötelező érvényűnek tekinted magadra nézve.<br />
<br />
1. Szerződési feltételek alapfogalmai:<br />
1.1. Szolgáltató adatai<br />
• A szolgáltató neve: Gáborjányi Benedek EV.<br />
• A szolgáltató székhelye: 1147 Bp, Balázs u. 36.<br />
• EV nyilvántartási szám: 56373598<br />
• Adószám: 57722139-1-42<br />
• Adatkezelő: Gáborjányi Benedek<br />
• A szerződés nyelve: magyar<br />
• A továbbiakban mint „Szolgáltató”.<br />
<br />
1.2. Szolgáltató elérhetősége<br />
• Levelezési címe: 1147 Bp, Balázs u. 36.<br />
• Telefonszám: +36-70/342-2898<br />
• E-mail: info@konyvkupac.com<br />
• Az ügyfélszolgálat minden nap 10:00 -22:45 óra között hívható.<br />
<br />
1.3. Tárhelyszolgáltató adatai:<br />
• Cégnév: DotRoll Kft.<br />
• Cím: 1148 Budapest, Fogarasi út 3-5.<br />
• E-mail: support@dotroll.com<br />
• Weboldal: dotroll.com<br />
<br />
1.4. A Vásárló<br />
Aki a Szolgáltató hivatalos honlapján (https://konyvkupac.com) keresztül valamilyen szolgáltatást vagy terméket vásárol, és az ÁSZF-et tudomásul veszi, illetve azt magára nézve is kötelezőnek fogadja el.<br />
<br />
2. Általános Szerződési Feltételek célja:<br />
Az Általános Szerződés Feltételei (ÁSZF) Szolgáltató és a vele szerződéses kapcsolatba lépő Vásárló között létrejövő jogviszony általános feltételeit tartalmazza. Az itt nem szabályozott kérdéseket illetően Szolgáltató tevékenységére vonatkozó mindenkor hatályos magyar jogszabályok és hatósági előírások, valamint a Polgári Törvénykönyvben foglalt rendelkezések külön kikötés nélkül irányadóak.<br />
2.1. Szerződési Feltételek elfogadása<br />
A Szerződési Feltételek Vásárló által történő elfogadása a vásárlás, illetve szolgáltatás igénybevételének előfeltétele. A Feltételek elfogadásának minősül, ha Vásárló a Szolgáltató által nyújtott szolgáltatást igénybe veszi.<br />
<br />
2.2. Szerződési Feltételek hatálya<br />
Az ÁSZF módosítására Szolgáltató bármikor egyoldalúan jogosult azzal a feltétellel, hogy a módosítás a Vásárló szerződéses kötelezettségeit nem teheti terhesebbé, szerződésben biztosított jogait nem vonhatja el, jogállását hátrányosan nem módosíthatja. A módosított ÁSZF magyar nyelvű verzióját Vásárló bármikor elérheti Szolgáltató weboldalán az Általános Szerződési Feltételek menüpontban. A módosítás érvénybelépése a közzétételtől számított 10. naptári nap és a hatályba lépést megelőzően létrejött szerződéseket nem érinti.<br />
Az ÁSZF változásáról annak közzétételekor Szolgáltató minden regisztrált Vásárlót a regisztrációkor megadott e-mail címen írásban értesít.<br />
Az ÁSZF változását és annak hatályba lépését követően Vásárló az első bejelentkezéskor felugró ablakban (popup) azonnal tájékoztatásra kerül a módosított ÁSZF tartalmával. Azt elolvasva pedig eldöntheti, hogy elfogadja azt, vagy törli a regisztrációját. Erre magában a felugró ablakban van lehetősége. Amíg nem él valamelyik lehetőséggel, a felugró ablak nem zárható be.<br />
Módosítás esetén a korábban érvényben lévő ÁSZF-et szolgáltató letölthetővé teszi PDF formátumban, dátum megjelöléssel az Általános Szerződési Feltételek menüpontban.<br />
<br />
3. A szolgáltatás:<br />
Szolgáltató az alábbi feltételek szerint nyújtja a szolgáltatást.<br />
<br />
3.1. A szolgáltatás területi hatálya<br />
Szolgáltató által üzemeltetett szolgáltatás mind belföldről, mind külföldről elérhető.<br />
<br />
3.2. Megvásárolható termékek, szolgáltatások köre<br />
A webshopban a Szolgáltató részletesen feltünteti a termékek nevét, rövid leírását, valamint a termékekről fotót jeleníttet meg. A termékek adatlapján megjelenített képek színei a valóságban bizonyos fokig eltérhetnek a megjelenítettektől, függően Vásárló technikai eszközének megjelenítési képességeitől. Szolgáltató a termékfotók elkészítésekor törekszik a legvalósághűbb színek megjelenítésére Vásárló számára. Valamennyi megjelenítés, adat, közlés, reklám vagy egyéb ilyen jellegű információ nem minősül ajánlattételnek, továbbá Szolgáltató az ajánlati kötöttségét előre kizárja. A pontos árakért és információkért keresd ügyfélszolgálatunkat.<br />
<br />
3.3. Akciók, kedvezmények kezelése<br />
Amennyiben akciós ár kerül bevezetésre, Szolgáltató a weboldalon tájékoztatja Felhasználókat az akció időtartamáról és annak érvényesítésének módjáról. Amennyiben az akció nyilvánvalóan téves (különösen, de nem kizárólagosan az akciós ár irreálisan észszerűtlen stb.) úgy az ezért való felelősséget és kötöttséget Szolgáltató kizárja.<br />
<br />
4. Szerződés létrejötte és módosítása:<br />
4.1. Általános jellemzők<br />
A Szerződés akkor jön létre, ha a 2.1. pont értelmében a Vásárló a Szolgáltató által nyújtott szolgáltatást igénybe veszi egyben a jelen Szerződési Feltételek elfogadását jelenti. Megrendelés akkor jön létre, amikor Vásárló a Megrendelést végső formájában jóváhagyja. Vásárló a megrendelés tartalmáról e-mailben tájékoztatást kap.<br />
<br />
4.2. Szolgáltatás módosítása, törlése<br />
Határidőhöz kötött szolgáltatás vásárlása esetében a szolgáltatás jóváhagyása után 14 munkanapon belül lehet élni a szolgáltatás módosításával vagy a szolgáltatástól való elállással, de legfeljebb a szolgáltatás nyújtását megelőző 3.napig. Amennyiben a terméket megrendelésre, külföldi készletről vagy közvetlenül a gyártótól hozzuk be, úgy az egyedi terméknek vagy egyedi gyártásnak minősül, ezáltal nem vonatkozik rá a 14 napos elállás. Kérjük, minden esetben érdeklődj a termék forrásáról. Az elállásról részletesen az ÁSZF 5. pontja rendelkezik.<br />
<br />
4.3. Fizetési feltételek<br />
• Utánvét: a megrendelés végösszegét a vásárló a futárszolgálat sofőrjének fizeti ki, a termék átvételekor.<br />
<br />
4.4. Szállítási feltételek<br />
A szállítás költsége a Vásárlót terheli. Azonban Szolgáltató vállalja a felelősséget a szállítás pontosságáért és biztonságáért, a szállítás során felmerült károkért.<br />
Amennyiben Vásárló egyszer megtagadja a megrendelt termék átvételét vagy elmulasztja azt, a Szolgáltató dönthet úgy, hogy csak előre utalás esetén szállítja azt újra ki, vagy fogad el új megrendelést az adott Vásárlótól.<br />
<br />
5. Elállási jog<br />
Az e pontban foglaltak kizárólag a szakmája, önálló foglalkozása vagy üzleti tevékenysége körén kívül eljáró természetes személyre vonatkozik, aki árut vesz, rendel, kap, használ, igénybe vesz, valamint az áruval kapcsolatos kereskedelmi kommunikáció, ajánlat címzettje (a továbbiakban „Fogyasztó"). Fogyasztó a termék adásvételére irányuló szerződés esetén jogosult:<br />
• a terméknek,<br />
• több termék szolgáltatásakor az utoljára szolgáltatott terméknek,<br />
• több tételből vagy darabból álló termék esetén az utoljára szolgáltatott tételnek vagy darabnak,<br />
• ha a terméket meghatározott időszakon belül rendszeresen kell szolgáltatni, az első terméknek, a Fogyasztó vagy az általa megjelölt, a fuvarozótól eltérő harmadik személy általi átvételének napjától számított tizennégy (14) napon belül a szerződéstől indokolás nélkül elállni.<br />
<br />
A Fogyasztót megilleti, hogy a szerződés megkötésének a napja és a termék átvételének napja közötti időszakban is gyakorolja elállási jogát.<br />
Fogyasztó, az elállási szándékát írásban, e-mailben vagy postai levél útján erre irányuló egyértelmű nyilatkozatával közölheti.<br />
Írásban történő elállás esetén azt határidőben érvényesítettnek tekintjük, ha az elállásra irányuló nyilatkozat 14 napon belül (akár a 14. napon) feladásra kerül részünkre. Postai úton történő jelzés alkalmával a postára adás dátumát, e-mail értesítés esetén az e-mail küldésének időpontját vesszük figyelembe a határidő számítás szempontjából. Fogyasztót terheli annak bizonyítása, hogy elállási jogát az ebben a pontban meghatározott rendelkezéseknek megfelelően gyakorolta. Fogyasztó elállás esetén köteles a megrendelt terméket az 1-es pontban feltüntetett címünkre indokolatlan késedelem nélkül, de legkésőbb elállási nyilatkozat közlésétől számított 14 napon belül visszaküldeni. A határidő betartottnak minősül, ha Fogyasztó a 14 napos határidő letelte előtt elküldi (postára adja vagy az általa megrendelt futárnak átadja) a terméket.<br />
Elállás esetén a termék visszajuttatásának költségei a Fogyasztót terhelik. Ezen felül egyéb költség nem terheli a Fogyasztót. Az utánvétellel visszaküldött csomagot nem áll módunkban átvenni, az ilyen csomag visszajuttatásának költsége is a Fogyasztót terheli! A termékeket lehetőség szerint ajánlott küldeményként kérjük visszajuttatni címünkre, hogy biztosan megérkezzen.<br />
Elérhetőségeink, ahol a vásárlástól való elállási szándék bejelenthető, valamint a csomag feladásához szükséges címünk:<br />
<br />
• Levelezési cím: 1147 Bp, Balázs u. 36.<br />
• E-mail cím: info@konyvkupac.com<br />
<br />
<br />
Kérjük, figyelj arra, hogy a visszaküldött termék lehetőség szerint gyári csomagolásban legyen, illetve, ha már felbontottad a termék csomagolását, akkor ügyelj arra, hogy a csomagolás részei, a termék és annak tartozékai, valamint a leírása is sértetlen állapotban legyen.<br />
A visszaküldött termék árát az elállásról való tudomásszerzéstől számított 14 napon belül visszatérítjük Fogyasztó számára. A visszatérítés vonatkozik a termék(ek) árára, valamint a fogyasztó részére történő kiszállítás költségeire is, kivéve, ha az elállással érintett termékkel együtt más olyan termék is kiszállításra került, amely tekintetében a fogyasztó nem élt elállási jogával.<br />
Szolgáltató visszatarthatja a fogyasztónak visszajáró összeget, amíg a fogyasztó a terméket vissza nem szolgáltatja, vagy kétséget kizáróan nem igazolja, hogy azt visszaküldte a Szolgáltató 1-es pontban, valamint ezen pontban megadott címére. A két lehetőség közül a korábbi időpontot vesszük figyelembe.<br />
Fogyasztó kizárólag akkor vonható felelősségre a termékben bekövetkezett értékcsökkenésért, ha az a termék jellegének, tulajdonságainak és működésének megállapításához szükséges használatot meghaladó használat miatt következett be. Megkövetelhetjük a termék jellegének, tulajdonságainak és működésének megállapításához szükséges használatot meghaladó használatból eredő értékcsökkenés, illetve azok észszerű költségeinek megtérítését.<br />
A visszatérítés során az eredeti ügylet során alkalmazott fizetési móddal egyező fizetési módot alkalmazunk, kivéve, ha más fizetési mód igénybevételéhez Fogyasztó kifejezetten hozzájárul. Fogyasztót e visszatérítési mód alkalmazásából kifolyólag semmilyen többletköltség nem terheli.<br />
<br />
5.1. Fogyasztót nem illeti meg az elállási jog<br />
• olyan nem előre gyártott termék esetében, amelyet a Fogyasztó utasítása alapján vagy kifejezett kérésére állított elő a Szolgáltató, vagy olyan termék esetében, amelyet egyértelműen a Vásárló személyére szabtak;<br />
• azon termékek vonatkozásában, ahol a rendeltetésszerű használat megkezdése után egészségügyi okok miatt nem küldhető vissza (pl. fehérneműk, harisnyák)<br />
<br />
6. Jótállás<br />
Az egyes tartós fogyasztási cikkekre vonatkozó kötelező jótállásról szóló jogszabály (151/2003. (IX.22.) – ) alapján, Szolgáltató jótállásra nem köteles.<br />
<br />
7. Szavatosság<br />
Weboldalunk rendelkezik egy könnyen kezelhető felülettel, ahol Vásárló jelezni tudja szavatossági igényét felénk. A felület IDE KATTINTVA érhető el, vagy a "Fiókom" menüpontban, a "Garanciális ügyintézés" gombot választva.)<br />
<br />
7.1. Kellékszavatosság<br />
Milyen esetben élhet a Vásárló kellékszavatossági jogával?<br />
Vásárló, Szolgáltató hibás teljesítése esetén a vállalkozással szemben kellékszavatossági igényt érvényesíthet a Polgári Törvénykönyv szabályai szerint.<br />
Milyen jogok illetik meg a Vásárlót kellékszavatossági igénye alapján?<br />
Választása szerint Vásárló az alábbi kellékszavatossági igényekkel élhet:<br />
<br />
• Jogosult kijavítást vagy kicserélést igényelni, kivéve, ha a választott kellékszavatossági jog teljesítése lehetetlen, vagy ha az az eladónak - másik kellékszavatossági igény teljesítésével összehasonlítva - aránytalan többletköltséget eredményezne, figyelembe véve a szolgáltatás hibátlan állapotban képviselt értékét, a szerződésszegés súlyát és a kellékszavatossági jog teljesítésével az ügyfélnek okozott érdeksérelmet.<br />
• Igényelheti az ellenszolgáltatás arányos leszállítását, a hibát az eladó költségére önmaga kijavíthatja vagy mással kijavíttathatja, vagy a szerződéstől elállhat, ha az eladó a kijavítást vagy a kicserélést nem vállalta, e kötelezettségének a vonatkozó jogszabályokban rögzített feltételekkel nem tud eleget tenni, vagy ha az ügyfélnek a kijavításhoz vagy kicseréléshez fűződő érdeke megszűnt. Jelentéktelen hiba miatt elállásnak nincs helye. A kijavítást vagy kicserélést - a dolog tulajdonságaira és az ügyfél által elvárható rendeltetésére figyelemmel - megfelelő határidőn belül, az ügyfél érdekeit kímélve kell elvégezni.<br />
• Választott kellékszavatossági jogáról Vásárló áttérhet egy másikra, áttérés költségét azonban ilyen esetben a Vásárló viseli, kivéve, ha az indokolt volt, vagy arra a vállalkozás adott okot.<br />
<br />
Milyen határidőben érvényesítheti a Vásárló kellékszavatossági igényét?<br />
Vásárló köteles a hibát annak felfedezése után haladéktalanul, de nem később, mint a hiba felfedezésétől számított kettő hónapon belül közölni. Ugyanakkor felhívjuk a figyelmet, hogy a szerződés teljesítésétől számított kétéves elévülési határidőn túl kellékszavatossági jogát már nem érvényesítheti. Használt dolog esetén ez a határidő legfeljebb egy év.<br />
<br />
Kivel szemben érvényesítheti Vásárló a kellékszavatossági igényét?<br />
A vállalkozással szemben érvényesítheti Vásárló a kellékszavatossági igényét.<br />
<br />
Milyen egyéb feltétele van kellékszavatossági jog érvényesítésének?<br />
A teljesítéstől számított hat hónapon belül a kellékszavatossági igény érvényesítésének a hiba közlésén túl nincs egyéb feltétele, ha Vásárló igazolja, hogy a terméket, illetve a szolgáltatást a Szolgáltató nyújtotta. A teljesítéstől számított hat hónap eltelte után azonban már Vásárló köteles bizonyítani, hogy az általad felismert hiba már a teljesítés időpontjában is megvolt.<br />
<br />
7.2. Termékszavatosság<br />
Milyen esetben élhet a Vásárló termékszavatossági jogával?<br />
Ingó dolog (termék) hibája esetén – választása szerint – kellékszavatossági igényt vagy termékszavatossági igényt érvényesíthet.<br />
<br />
Milyen jogok illetik meg a Vásárlót termékszavatossági igénye alapján?<br />
Termékszavatossági igényként kérheti a hibás termék kijavítását, kicserélését vagy ellenszolgáltatás arányos leszállítását.<br />
<br />
Milyen esetben minősül a termék hibásnak?<br />
A termék akkor hibás, ha az nem felel meg a forgalomba hozatalakor hatályos minőségi követelményeknek vagy pedig, ha nem rendelkezik a gyártó által adott leírásban szereplő tulajdonságokkal.<br />
<br />
Milyen határidőben érvényesítheti a Vásárló termékszavatossági igényét?<br />
Termékszavatossági igényét Vásárló a termék gyártó általi forgalomba hozatalától számított két éven belül érvényesítheti. E határidő elteltével e jogosultságot elveszíti.<br />
<br />
Kivel szemben és milyen egyéb feltételekkel érvényesítheti Vásárló termékszavatossági igényét?<br />
Termékszavatossági igényét Vásárló kizárólag az ingó dolog gyártójával vagy forgalmazójával szemben gyakorolhatja. A termék hibáját termékszavatossági igény érvényesítése esetén Vásárlónak kell bizonyítania.<br />
<br />
A gyártó (forgalmazó) milyen esetben mentesül termékszavatossági kötelezettsége alól?<br />
A gyártó (forgalmazó) kizárólag akkor mentesül termékszavatossági kötelezettsége alól, ha bizonyítani tudja, hogy:<br />
• a terméket nem üzleti tevékenysége körében gyártotta, illetve hozta forgalomba, vagy<br />
• a hiba a tudomány és a technika állása szerint a forgalomba hozatal időpontjában nem volt felismerhető vagy<br />
• a termék hibája jogszabály vagy kötelező hatósági előírás alkalmazásából ered.<br />
<br />
A gyártónak (forgalmazónak) a mentesüléshez elegendő egy okot bizonyítania.<br />
Felhívjuk a figyelmet, hogy ugyanazon hiba miatt kellékszavatossági és termékszavatossági igényt egyszerre, egymással párhuzamosan Vásárló nem érvényesíthet. Termékszavatossági igényének eredményes érvényesítése esetén azonban a kicserélt termékre, illetve kijavított részre vonatkozó kellékszavatossági igényét a gyártóval szemben érvényesítheti.<br />
<br />
8. Panaszkezelés<br />
A webáruház működésével, megrendelési, és szállítási folyamattokkal illetőleg a termékekkel kapcsolatosan kifogásaival Vásárló az alább feltüntetett elérhetőségeken keresztül tud velünk kapcsolatba lépni:<br />
• Az ügyfélszolgálat minden nap 10:00 -22:45 óra között hívható<br />
• Telefonszám: +36-70/342-2898<br />
• E-mail: info@konyvkupac.com<br />
• Weboldal: konyvkupac.com<br />
• Levelezési cím: 1147 Bp, Balázs u. 36.<br />
<br />
A szóbeli panaszt azonnal megvizsgáljuk, és szükség szerint orvosoljuk, amennyiben ezt a panasz jellege megengedi. Amennyiben Vásárló a panasz kezelésével nem ért egyet, vagy a panasz azonnali kivizsgálása nem lehetséges, a panaszról és az azzal kapcsolatos álláspontjáról haladéktalanul jegyzőkönyvet veszünk fel, és annak egy másolati példányát:<br />
• személyesen közölt szóbeli panasz esetén helyben adjuk át a Vásárlónak<br />
• telefonon, írásban (levélben vagy egyéb elektronikus úton) közölt panasz esetén kötelesek vagyunk 30 napon belül érdemben megválaszolni, az írásbeli panaszra vonatkozó rendelkezések szerint eljárni<br />
<br />
Ha Vásárló nem ért egyet a panasz orvoslásával, köteles álláspontját az elutasításra vonatkozó érdemi válaszában megindokolni.<br />
A panaszról felvett jegyzőkönyvet és a Vásárló válaszának másolati példányát 5 évig megőrizzük.<br />
Panaszkezelés esetében az írásbeli panaszra vonatkozó szabályok szerint járunk el.<br />
Az írásban érkezett panaszt általában 1-2 napon belül, de legkésőbb a jogszabályban előírt 30 napon belül érdemben megválaszoljuk. Az intézkedés határidejét jelen szerződés értelmében a postára adás dátumától ill. az e-mail beérkezésének dátumától számítjuk.<br />
A panasz elutasítása esetén az elutasítás indokáról tájékoztatjuk a Vásárlót.<br />
<br />
8.1. Egyéb jogérvényesítési lehetőségek<br />
Amennyiben a fennálló fogyasztói jogvita a velünk való tárgyalások során nem rendeződik, az alábbi jogérvényesítési lehetőségek nyitottak Vásárló számára:<br />
• panasztétel a fogyasztóvédelmi hatóságnál<br />
• békéltető testület eljárásának kezdeményezése<br />
• bírósági eljárás kezdeményezése<br />
Miután a Nemzeti Fogyasztóvédelmi Hatóság 2016. december 31. napjával jogutódlással megszűnt, 2017. január 1-től panaszaikkal elsősorban a területileg illetékes járási hivatalokhoz fordulhatnak a Vásárlók. A járási hivatalok elérhetőségét IDE KATTINTVA tekinthetjük meg.<br />
<br />
8.1.1. Békéltető testület<br />
A békéltető testület a megyei (fővárosi) kereskedelmi és iparkamarák mellett működő független testület. A békéltető testület hatáskörébe tartozik a fogyasztói jogvita bírósági eljáráson kívüli rendezése. Feladata továbbá, hogy megkísérelje a fogyasztói jogvita rendezése céljából egyezség létrehozását a felek között, ennek eredménytelensége esetén az ügyben döntést hoz a fogyasztói jogok egyszerű, gyors, hatékony és költségkímélő érvényesítésének biztosítása érdekében. A békéltető testületek elérhetőségét IDE KATTINTVA találjuk meg.<br />
A békéltető testület a fogyasztó vagy a vállalkozás kérésére tanácsot ad a fogyasztót megillető jogokkal és a fogyasztót terhelő kötelezettségekkel kapcsolatban.<br />
Tájékoztatásul közöljük, hogy a békéltető testületi eljárás megindításának feltétele, hogy a fogyasztó az érintett vállalkozással közvetlenül megkísérelje a vitás ügy rendezését.<br />
A békéltető testületre és bíróságra vonatkozó szabályok alkalmazásában fogyasztónak minősül a külön törvény szerinti civil szervezet, egyház, társasház, lakásszövetkezet, mikro-, kis- és középvállalkozás is, aki árut vesz, rendel, kap, használ, igénybe vesz, vagy az áruval kapcsolatos kereskedelmi kommunikáció, ajánlat címzettje.<br />
A békéltető testületi eljárásban együttműködési kötelezettség terheli cégünket, ennek keretében kötelesek vagyunk a válasziratot megküldeni a békéltető testület számára.<br />
<br />
8.1.1.1. A békéltető testületi eljárás megindítása<br />
Az eljárásra elsősorban a fogyasztó lakóhelye vagy tartózkodási helye szerinti békéltető testület illetékes. Felhívjuk a figyelmet, hogy a békéltető testületi eljárás megindításának feltétele, hogy a fogyasztó az érintett vállalkozással közvetlenül megkísérelje a vitás ügy rendezését. A békéltető testület eljárása tehát a fogyasztó kérelmére indul.<br />
A kérelmet a békéltető testület elnökéhez kell írásban benyújtani. A kérelemnek tartalmaznia kell<br />
• a fogyasztó nevét, lakóhelyét vagy tartózkodási helyét<br />
• a fogyasztói jogvitával érintett vállalkozás nevét, székhelyét vagy érintett telephelyét<br />
• – ha a fogyasztó az illetékességet az Fgytv. 20. § (3) bekezdésére kívánja alapítani – a 20. § (1) és (2) bekezdése szerint illetékes békéltető testület helyett kérelmezett testület megjelölését<br />
• a fogyasztó álláspontjának rövid leírását, az azt alátámasztó tényeket és azok bizonyítékait<br />
• a fogyasztó nyilatkozatát a 27. §-ban előírt feltétel teljesítéséről<br />
• a fogyasztó nyilatkozatát arra nézve, hogy az ügyben más békéltető testület eljárását nem kezdeményezte, közvetítői eljárás nem indult, keresetlevél beadására, illetve fizetési meghagyás kibocsátása iránti kérelem előterjesztésére nem került sor<br />
• a testület döntésére irányuló indítványt<br />
• a fogyasztó aláírását<br />
<br />
A kérelemhez csatolni kell azt az okiratot, illetve annak másolatát (kivonatát), amelynek tartalmára a fogyasztó bizonyítékként hivatkozik, így különösen a vállalkozás írásbeli nyilatkozatát a panasz elutasításáról, ennek hiányában a fogyasztó rendelkezésére álló egyéb írásos bizonyítékot a 27. §-ban előírt egyeztetés megkísérléséről.<br />
Ha a fogyasztó meghatalmazott útján jár el, a kérelemhez csatolni kell a meghatalmazást.<br />
A cég székhelye szerint illetékes megyei békéltető testület adatai:<br />
• Név: Budapesti Kereskedelmi és Iparkamara mellett működő Budapesti Békéltető Testület<br />
• Cím: 1016 Budapest, Krisztina krt. 99.<br />
• Telefonszám: +36-1 / 488-2131<br />
• E-mail cím: bekelteto.testulet@bkik.hu<br />
• Weboldal: www.bekeltet.hu<br />
<br />
8.1.2. Bírósági eljárás<br />
A Vásárló jogosult a követelésének bíróság előtti érvényesítésére polgári eljárás keretében Polgári Törvénykönyvről szóló 2013. évi V. törvény, valamint a polgári perrendtartásról szóló 2016. évi CXXX. törvény rendelkezései szerint.<br />
A cég székhelye szerint illetékes bíróság adatai:<br />
• Név: Pesti Központi Kerületi Bíróság<br />
• Cím: 1055 Budapest, Markó u. 25.<br />
• Postacím: 1887 Budapest, Pf: 28.<br />
• Telefonszám: +36-94 / 314-365<br />
• Fax: +36-1 / 354-6000<br />
• E-mail: pkkb@birosag.hu<br />
• Weboldal: Pesti Központi Kerületi Bíróság<br />
<br />
Online adásvételi szerződéssel összefüggő határon átnyúló fogyasztói jogvita esetén a fogyasztók az online vásárláshoz kapcsolódó, határon átnyúló jogvitáikat elektronikusan rendezni tudják a következő linken elérhető online platformon keresztül beadott elektronikus panasz útján.<br />
Ehhez nem kell mást tenni, minthogy a fenti linken elérhető online platformon a fogyasztó beregisztrál, kitölt egy kérelmet hiánytalanul, majd elektronikusan beküldi a Békéltető Testület részére a platformon keresztül. Így a fogyasztók, a távolságok ellenére egyszerűen tudják érvényesíteni jogaikat.<br />
<br />
8.1.3. OVR - Online Vitarendezési Platform<br />
Az Európai Bizottság által működtetett európai online vitarendezési platform (OVR) célja, hogy a színvonalas vitarendezés lehetőségét biztosítva biztonságosabbá és tisztességesebbé tegye az online vásárlást.<br />
A vitarendezési testület egy olyan semleges harmadik fél, amely segít abban, hogy a fogyasztók és a kereskedők konfrontáció nélkül oldhassák meg a vitás kérdéseket. Ez a bírósági eljárásnál általában kevésbé költséges és gyorsabb alternatívát kínál.<br />
30 nap áll a rendelkezésükre, hogy megállapodjanak, melyik vitarendezési testület járjon el az ügyben. Amennyiben ezt elmulasztják, a fogyasztó tájékoztatást kap arról, hogy milyen további lehetőségei vannak jogai megvédésére és a jogorvoslatra.<br />
<br />
Az OVR Platform az alábbi linken érhető el: OVR<br />
<br />
9. Jogok és kötelezettségek<br />
9.1. A Szolgáltató szolgáltatási kötelezettsége és felelőssége<br />
9.1.1.: A Szolgáltató a Szolgáltatást a Vásárló részére folyamatosan biztosítja a Vásárló fizetési kötelezettségének teljesítése ellenében.<br />
9.1.2.: A Szolgáltató nem felel olyan károkért, amelyek, erőhatalom, természeti csapás (vis major), vagy egyéb, a Szolgáltató érdekkörén kívülálló ok, illetve esemény folytán következnek be, amelyekért a Szolgáltató nem tehető felelőssé.<br />
9.1.3.: A Szolgáltató az árváltozás jogát fenntartja. Az oldalon lévő esetleges hibákért felelősséget nem vállal. Az ellenérték tartalmazza a szolgáltatásnak megfelelő terhelő közterheket, illetve az Általános Forgalmi Adót (ÁFA).<br />
9.1.4.: A Szolgáltató a Vásárló adatait a szerződés teljesítése, és a szerződés feltételeinek későbbi bizonyítása érdekében tárolja, azokat azonban továbbadni sem reklámfelhasználás sem egyéb célra - a Vásárló kifejezett hozzájárulása hiányában - nem jogosult.<br />
<br />
9.2. A Vásárló jogai és kötelezettségei<br />
9.2.1.: A vásárló az adatok megadása során megadott téves adatokért, és az azokból adódó károkért semmilyen formában nem vállalunk felelősséget a hibás adatokból eredő esetleges téves teljesítésért.<br />
9.2.2.: Amennyiben Vásárló valamely adatát hibásan adta meg a regisztráció során, azt javítani tudja a webáruház fiókom menüpontjában.<br />
9.2.3.: A Vásárló kötelessége a szolgáltatás fizetési mód szerinti megfizetése (bankkártyás fizetés, utánvétel). A választott fizetési módtól a Vásárló csak a Szolgáltató előzetes hozzájárulásával térhet el. Amennyiben a megrendelt szolgáltatás vételárát a Vásárló nem tudja megfizetni, akkor a megrendelt szolgáltatás / termék automatikusan törlődik a rendszerből.<br />
<br />
10. A felelősség korlátozása<br />
A webáruházban való vásárlás feltételezi a vásárló részéről az Internet lehetőségeinek és korlátainak ismeretét és elfogadását, különös tekintettel a technikai teljesítményekre és a felmerülő hibákra. A Szolgáltató semmilyen módon nem felelős az alább felsorolt pontokban foglaltak miatt, bármilyen okból is következtek be:<br />
• Az interneten küldött és / vagy fogadott bármilyen hibás adat.<br />
• Bármilyen működési hiba az internetes hálózatban, ami megakadályozza a webáruház akadálytalan működését és a vásárlást.<br />
• Bármilyen meghibásodás, bármely vételi eszközben a kommunikációs vonalakon.<br />
• Bármely levél - függetlenül attól, hogy papír, vagy elektronikus formában érkezett -, de főleg bármilyen adat elvesztése.<br />
• Bármely szoftver nem megfelelő működése.<br />
• Bármilyen programhiba, abnormális esemény vagy technikai hiba következményei.<br />
<br />
A Szolgáltató nem felelős semmilyen alapon, semmilyen közvetett vagy közvetlen kárért, ami a webáruházhoz való csatlakozás miatt a vásárlónál következett be. Egyedül a vásárló felelős a webáruházhoz való kapcsolódásáért és a webáruházban való vásárlásért. A Szolgáltató nem vonható felelősségre semmilyen esetben vis maior esetén vagy bármilyen eseménnyel kapcsolatosan, ami nem az ő érdekkörében merül fel. Bármely vásárlónak, aki nem ért egyet a szabályok módosításával, fel kell hagynia a vásárlással. Ha a vásárló bármilyen formában megsérti a szabályokat, a Szolgáltató szabadon érvénytelennek nyilváníthatja regisztrációját, vásárlását és a vevő nem jogosult kifogást emelni a döntés ellen és kártérítést követelni. A résztvevő felelőssége felmérni, hogy hogyan védheti meg a számítógépén tárolt adatait a behatolóktól. A webáruházban szerepelő képek szerzői jogvédelem alatt állnak, azok engedély nélküli felhasználását, módosítását a törvény bünteti.<br />
<br />
Jogi rendelkezések:<br />
1. A szolgáltatóra vonatkozó adatokról és az elektronikus szerződéskötés lépéseivel kapcsolatban az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről szóló 2001. évi CVIII. törvény tartalmaz rendelkezéseket.<br />
2. Az elállási jog gyakorlásának részletes feltételeit a távollevők között kötött szerződésekről szóló 45/2014. (II. 26.) Kormányrendelet szabályozza.<br />
3. Az internetes vásárlásokra vonatkozó jogszabályok és a sérelmi díjat tartalmazó rendelkezéseket a fogyasztóvédelemről szóló 1997. évi CLV. törvény és a Polgári Törvénykönyvről szóló 2013. évi V. törvény rendelkezései tartalmazzák.<br />
                </div>
            </div>
        )
    }
}
export default Aszf;